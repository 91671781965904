import React, { useEffect } from 'react';
import { navigate, Link } from 'gatsby';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../Store';
import Layout from '../components/layouts/Layout/app_index';
import SEO from '../components/seo';

import '../components/ticket_checker/style.scss';
import scan_img from '../global/cms/pa_scanning_image.png';
import { getHTML, getText } from '../utils/cms_copy_provider';
import isBrowser from '../utils/isBrowser';
import HelpModal from './ticket_checker/help_modal';
import PageLoading from '../components/widgets/page_loading';

export const TicketChecker = ({ user, entry, checkTicket, notification, config, actions }) => {
    return (
        <Layout user={user} config={config} notification={notification} actions={actions}>
            <SEO title='Ticket Checker' />
            <MainPage user={user} entry={entry} ticket={checkTicket} actions={actions} config={config} />
        </Layout>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(TicketChecker);

const MainPage = ({ user, entry, ticket, actions, config }) => {
    useEffect(() => {
        clearIfSuccess();
    }, [ticket]);

    const scanCallback = function(infoObject) {
        const sub1 = infoObject.format.toUpperCase();
        const webCodeBarCode = infoObject.result;

        if (sub1 !== 'UNDEFINED' || infoObject.result !== null || infoObject.result !== '') {
            let sending = {
                ticket1_1_1: webCodeBarCode,
                entry_method: 'scan',
            };
            actions.checkTicketActions.checkTicket(sending);
        }
    };

    // if the form submit was a success, we clear the form
    const clearIfSuccess = async () => {
        if (ticket.success) {
            navigate('/ticket_checker/post_check', {
                state: { returnURL: '/ticket_checker', entry_method: 'scan' },
            });
        } else if (ticket.message) {
            actions.entryActions.setLastEntry({
                Bonus: [],
                TicketDetails: {
                    TicketStatus: 'NOT_AVAILABLE',
                    IsPromotionEligible: 'UNKNOWN',
                },
                ResultCode: 0,
                Message: 'Success',
                ExtendedErrors: null,
            });
            navigate('/ticket_checker/post_check', {
                state: { returnURL: '/ticket_checker' },
            });
        }
    };

    const doScan = () => {
        if (isBrowser() && typeof window.MdiNativeShell !== 'undefined') {
            window.MdiNativeShell.scanBarcode(scanCallback.bind(this));
        }
    };

    const loggedIn = (usr) => {
        if (usr.loggedIn) {
            return (
                <div className='text-center'>
                    <p className='welcome text-center'>
                        Welcome
                        <span className='user-name'>{usr.player ? usr.player.name : null}</span>
                    </p>
                </div>
            );
        } else {
            return null;
        }
    };

    return (
        <div className='ticket_checker'>
            <div className='container-fluid primary-container'>
                <div className='vip-stripe text-center'>
                    <span>{getText('ticket_checker_title')}</span>
                </div>
                <div className='row'>
                    <div className='col'>
                        <div className='scbz-content-area text-center'>
                            {loggedIn(user)}
                            <HelpModal />
                            <a href='javascript:void(0);' className='ticket-scan-btn'>
                                <img src={scan_img} alt='Scan Ticket' className='img-responsive center-block scan-image' />
                            </a>
                            <a href='javascript:void(0);' onClick={doScan} className='btn scan-link'>
                                {getText('ticket_checker_scan_btn')}
                            </a>
                            <p className='directive'>{getText('ticket_checker_directive')}</p>
                            <p className='divider'>
                                <span>or</span>
                            </p>
                            <p className='sub-scan-links'>
                                <span className='btn-block btn-sm btn-text text-left font-weight-bold'>MANUAL ENTRY</span>
                                <Link to='./manual' state={{ manualType: 'Scratch-Off' }} className='btn btn-block btn-sm btn-text text-left'>
                                    Scratch-Offs &#8250;
                                </Link>
                                <Link to='./manual' state={{ manualType: 'Fast Play' }} className='btn btn-block btn-sm btn-text text-left'>
                                    Fast Play &#8250;
                                </Link>
                                <a href={`${config?.config?.lottery_host}mobileapp/tc-manual.aspx`} className='btn btn-block btn-sm btn-text text-left'>
                                    Draw Games, Keno &amp; Xpress Sports &#8250;
                                </a>
                            </p>
                            <div className='text-left disclaimer time-disclaimer'>
                                <p>{getHTML('ticket_checker_time_disclaimer')}</p>
                            </div>
                            <div className='text-left disclaimer'>
                                <p>{getHTML('ticket_checker_second_disclaimer')}</p>
                            </div>
                            <PageLoading loadedAction='checkTicket' hasIcon='true' />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
