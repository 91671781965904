import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../../Store';

import './page_loading.scss';

/**
 * Should cover edge cases with loading items not displayed on the page
 *
 * @param loadedAction = the string name of the action being waited on in LoadingStore
 * @param hasIcon      = true will show a grayed out area with a loading icon
 * @param loading      = comes from mapStateToProps
 */
const PageLoading = ({ loadedAction, hasIcon, loading }) => {
    const [hasMounted, setHasMOunted] = useState(false);

    useEffect(() => {
        setHasMOunted(true);
    }, []);

    if (!hasMounted || loading.actions[loadedAction]) {
        if (hasIcon) {
            return (
                <div className='loading-wrapper'>
                    <div className='loading-overlay'>
                        <div className='loading-icon ellipses'></div>
                    </div>
                </div>
            );
        } else {
            return null;
        }
    } else {
        return null;
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(PageLoading);
